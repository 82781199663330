import freeAgent from '../images/professions/free_agent.png';
import hardwareDesigner from '../images/professions/hardware_designer.png';
import softwareDesigner from '../images/professions/software_designer.png';
import systemArchitect from '../images/professions/system_architrect.png';

export default {
  freeAgent,
  hardwareDesigner,
  softwareDesigner,
  systemArchitect
};
