import styled, { css } from 'styled-components';
import ThemeDivider from '../shared/Divider';

export const Container = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: grey;
  ${(props) =>
    props.isActive &&
    css`
      color: white;
    `}
`;

export const Icon = styled.img`
  height: 50px;
  width: 50px;
  object-fit: contain;
`;

export const Divider = styled(ThemeDivider)`
  background-color: grey;
`;

export const ContainerButton = styled.div`
  position: absolute;
  bottom: 32px;
  left: 15%;
  right: 15%;
`;

export const CheckMark = styled.span`
  font-size: 40px;
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.primary};
`;
