import React from 'react';
import Spacer from '../shared/Spacer';
import * as Styled from './style';

interface IProfessionItem {
  title: string;
  icon: string;
  isActive?: boolean;
  onClick: () => void;
}

const ProfessionItem: React.FC<IProfessionItem> = ({
  title,
  icon,
  isActive,
  onClick
}) => (
  <Styled.Container isActive={isActive} onClick={onClick}>
    <Styled.Icon src={icon} alt="profession icon" />
    <Spacer />
    <p>
      {isActive ? (
        <>
          {title}
          <Styled.CheckMark>&#10003;</Styled.CheckMark>
        </>
      ) : (
        title
      )}
    </p>
    <Spacer />
    <Styled.Divider />
  </Styled.Container>
);

export default ProfessionItem;
