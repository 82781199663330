import { navigate } from 'gatsby-link';
import React, { useState } from 'react';
import styled from 'styled-components';
import ProfessionItem from '../components/ProfessionItem';
import Button from '../components/shared/Button';
import Container from '../components/shared/Container';
import PageHeader from '../components/shared/PageHeader';
import Spacer from '../components/shared/Spacer';
import { saveProfession } from '../services/localStorage';
import { PageProps } from 'gatsby';
import professions from '../styles/professions';
import Breakpoints from '../services/breakpoints';

const items = [
  { title: 'Roaring Hardware Designer', icon: professions.hardwareDesigner },
  { title: 'Mighty Software Developer', icon: professions.softwareDesigner },
  { title: 'Fabolous System Architect', icon: professions.systemArchitect },
  { title: 'Thunderous Free Agent', icon: professions.freeAgent }
];

const ContainerMain = styled(Container)`
  box-sizing: border-box;
  @media screen and (min-width: ${Breakpoints.MOBILE}) {
    width: 80%;
  }
`;

const Profession: React.FC<PageProps> = ({ location }) => {
  const [currentProfession, setCurrentProfession] = useState(null);

  const handleProfessionClick = (title: string) => {
    setCurrentProfession(title);
  };

  const handleConfirmClick = async () => {
    await saveProfession(currentProfession);
    const fromForm = location.state.fromForm;
    if (fromForm) {
      navigate(-1);
    } else {
      navigate('/game');
    }
  };

  return (
    <ContainerMain>
      <PageHeader title="Select your profession" isSmallTitle />
      {items.map((item) => (
        <React.Fragment key={item.title}>
          <ProfessionItem
            title={item.title}
            icon={item.icon}
            isActive={item.title === currentProfession}
            onClick={() => handleProfessionClick(item.title)}
          />
          <Spacer spacing={2} />
        </React.Fragment>
      ))}
      <Button
        primary
        onClick={handleConfirmClick}
        disabled={!currentProfession}
      >
        CONFIRM
      </Button>
    </ContainerMain>
  );
};

export default Profession;
